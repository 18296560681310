import React from "react";
import { Button, Dropdown, Grid } from "@nextui-org/react";

//custom styles
import "./home.css";

export default function App() {
  const [selected, setSelected] = React.useState(new Set(["Select your institution"]));

  const selectedValue = React.useMemo(
    () => Array.from(selected).join(", ").replaceAll("_", " "),
    [selected]
  );

  function redirect_user(location) {
    window.location.href = "https://" + location + ".ridemap.in";
  }

  let stored_location = localStorage.getItem("location");

  const handleSubmit = () => {

    switch (selectedValue) {
      case "Manakula Vinayagar Institute of Technology":
        localStorage.setItem("location", "mvit");
        redirect_user("mvit");
        break;
      case "Sri Manakula Vinayagar Engineering College":
        localStorage.setItem("location", "smvec");
        redirect_user("smvec");
        break;
      case "Mailam Engineering College":
        localStorage.setItem("location", "mec");
        redirect_user("mec");
        break;
      case "Takshashila University":
        localStorage.setItem("location", "tu");
        redirect_user("tu");
        break;
      case "Sethu Institute Of Technology":
        localStorage.setItem("location", "sit");
        redirect_user("sit");
        break;
      default:
        break;
    }
  }

  if (stored_location !== null) {
    redirect_user(stored_location)
  } else {
    return (
      <div className="center">
        <div className="paddings"><img src={require("./logo.png")} alt="logo" /></div>

        <Dropdown>
          <Dropdown.Button solid color="success">
            {selectedValue}
          </Dropdown.Button>
          <Dropdown.Menu
            color="success"
            aria-label="Single selection actions"
            disallowEmptySelection
            selectionMode="single"
            css={{ $$dropdownMenuWidth: "280px", backgroundColor: "$accents2" }}
            selectedKeys={selected}
            onSelectionChange={setSelected}
            disabledKeys={["SMV School"]}
          >
            <Dropdown.Section title="Colleges" className="color">
              <Dropdown.Item
                css={{ padding: "0.5rem" }}
                key="Sri Manakula Vinayagar Engineering College"
              >
                Sri Manakula Vinayagar Engineering College
              </Dropdown.Item>
              <Dropdown.Item
                css={{ padding: "0.5rem" }}
                key="Manakula Vinayagar Institute of Technology"
              >
                Manakula Vinayagar Institute of Technology
              </Dropdown.Item>
              <Dropdown.Item
                css={{ padding: "0.5rem" }}
                key="Mailam Engineering College"
              >
                Mailam Engineering College
              </Dropdown.Item>
              <Dropdown.Item
                css={{ padding: "0.5rem" }}
                key="Takshashila University"
              >
                Takshashila University
              </Dropdown.Item>
              <Dropdown.Item
                css={{ padding: "0.5rem" }}
                key="Sethu Institute Of Technology"
              >
                Sethu Institute Of Technology
              </Dropdown.Item>
            </Dropdown.Section>
            <Dropdown.Section title="School">
              <Dropdown.Item
                css={{ padding: "0.5rem" }}
                key="SMV School"
              >
                SMV School
              </Dropdown.Item>
            </Dropdown.Section>
          </Dropdown.Menu>
        </Dropdown>
        <br />
        <Grid className="paddings"> <Button auto color="success" rounded bordered onClick={handleSubmit}>
          Track
        </Button></Grid>
      </div>
    );
  }
}
